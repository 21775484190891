import React, { useEffect, useRef } from 'react';
import NeonButton from './components/NeonButton';
import { Col, Grid, Row } from 'react-flexbox-grid';
import bg1Img from './assets/bg2.jpg';
import sergiImg from './assets/sergi.png';
import { BackgroundRenderer, loadImage, EffectType, Easings, BackgroundCamera } from 'midori-bg';

const headerStyles: React.CSSProperties = {
  textAlign: 'center',
  fontSize: '1.5em',
};

const h1Style: React.CSSProperties = {
  fontSize: '4em',
  paddingTop: 150,
  marginTop: 0,
  color: '#ffffff',
  textShadow: '0px 0px 5px rgba(255,255,255,0.5)',
};

const hiTextStyle: React.CSSProperties = {
  marginTop: 0,
  color: '#47ffb6',
};

const avatarStyle: React.CSSProperties = {
  backgroundColor: 'rgba(255, 255, 255, 0.2)',
  height: 120,
  width: 120,
  overflow: 'hidden',
  borderRadius: '50%',
  opacity: 1,
  border: '5px solid #47ffb6',
  display: 'inline-block',
};

const sergiImgStyle: React.CSSProperties = {
  height: '100%',
  display: 'block',
  margin: 'auto',
};

const smallTitleSyle: React.CSSProperties = {
  fontSize: '0.168em',
  display: 'block',
  position: 'relative',
  paddingTop: 0,
  marginTop: -15,
  marginRight: -30,
  color: '#ffffff',
  textShadow: '0px 0px 20px rgba(255,255,255,0.4)',
};

const canvasStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  zIndex: -9999,
  opacity: 0.2,
};

function splashBackground(camera: BackgroundCamera) {
  camera.move(
    { x: Math.random(), y: Math.random(), z: 0.7 },
    {
      duration: 0.5,
      easing: Easings.Elastic.Out,
    }
  );
}

function moveBackground(camera: BackgroundCamera) {
  camera.move(
    { x: 1, y: 1, z: 4 },
    {
      duration: 20,
      easing: Easings.Cubic.Out,
    }
  );
}

export default () => {
  const canvasEl = useRef<HTMLCanvasElement>(null!);
  let renderer: BackgroundRenderer;
  let globalCamera: BackgroundCamera;
  useEffect(() => {
    renderer = new BackgroundRenderer(canvasEl.current);
    loadImage(bg1Img).then((img) => {
      renderer.setBackground(img);
      const { effects, particles, camera } = renderer.background;
      globalCamera = camera;
      effects.set(EffectType.MotionBlur, { intensity: 20, samples: 32 });
      effects.set(EffectType.Vignette, { darkness: 1, offset: 2.1 });
      effects.set(EffectType.RgbShift, { amount: 0.009, angle: 135 });
      effects.set(EffectType.Bloom, { opacity: 1, radius: 1, passes: 2 });

      particles.generate([
        {
          name: 'small',
          amount: 75,
          minSize: 1,
          maxSize: 100,
          maxOpacity: 0.5,
          minGradient: 0.75,
          maxGradient: 1.0,
          color: 0xffffff,
          smoothing: 1,
        },
      ]);
      // sway the particles up to a given distance with a transition.
      particles.sway('small', { x: 0.025, y: 0.025 }, { duration: 5, easing: Easings.Sinusoidal.InOut, loop: true });
      particles.move('small', { distance: 0.2, angle: 35 }, { duration: 5, loop: true });
      // Camera movement
      moveBackground(camera);
    });
  }, [canvasEl]);
  return (
    <>
      <canvas ref={canvasEl} style={canvasStyle}></canvas>
      <div style={headerStyles}>
        <h1 className="TypoHeader" style={h1Style}>
          <span style={hiTextStyle}>👋🏼</span> I'm Sergi
          <span style={smallTitleSyle}>Software Engineer, 2 x father, tech lover</span>
        </h1>
      </div>
      <Grid fluid>
        <Row center="xs">
          <Col>
            <div style={avatarStyle}>
              <img style={sergiImgStyle} src={sergiImg} alt="Sergi" />
            </div>
          </Col>
        </Row>
        <Row center="xs">
          <Col>
            <NeonButton
              onClick={() => {
                splashBackground(globalCamera);
              }}
            >
              IN PROGRESS
            </NeonButton>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
