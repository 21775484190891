import React from 'react';
import styles from './NeonButton.module.scss';

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
}

const NeonButton = ({ children, ...props }: Props) => {
  return (
    <button className={styles.NeonButton} type="button" {...props}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      {children}
    </button>
  );
};

export default NeonButton;
